import React, { useEffect, useRef, useState, useCallback } from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';
import lottie from 'lottie-web';

import PageSection from '../components/PageSection';
import PageHeader from '../components/PageHeader/PageHeader';
import Arrow from '../components/Arrow';
import { Title, TagLine, SubTitle, Outline } from '../components/PageParts';
import { LottieOuter, LottieInner, Mock } from '../components/lottieParts';
import SlideInElement from '../components/SlideInElement/SlideInElement';
import Layout from '../components/layout';
import SlideFooter from '../components/SlideFooter';

import aboutUsAnimation from '../animation/about-us-animation.json';
import { fadeInLeft, fadeInUp } from '../style/motion-variants';
import { colors } from '../style/style';

import Slider from '../components/Slider/Slider';
import Scroll from '../components/Slider/Scroll';

import bgDarkElement from '../static/images/bg-element-dark.svg';
import bgLightElement from '../static/images/bg-element-light.svg';
import heroMock from '../static/images/mocks/about-us-hero.png';
import subHeroMock from '../static/images/mocks/about-us-second.png';
import arrowUp from '../static/images/icons/arrow_up.svg';
import arrowDown from "../static/images/icons/arrow_down.svg";
import { handleLottieStop, handleLottieTouch, handleTransformLottie } from '../utils/helpers/lottieHandler';
import useWindowDimensions from '../utils/hooks/useWindowWidth';

const tagLineText =
    'AmSuisse is an insurance wholesaler providing an extensive portfolio of' +
    ' products and specialty programs throughout the United States.' +
    ' We formed AmSuisse with the goal of truly serving the needs' +
    ' of our retail agency partners – something we could only do by working' +
    ' with a select group of firms as a closed wholesaler. This allowed us to' +
    ' establish a business that puts the focus on relationships, superior customer service,' +
    ' responsive quote turnaround, and marketing support';

export const query = graphql`
    {
        seo: datoCmsSeo(slug: { eq: "about-us" }) {
            meta: seoMetaTags {
                tags
            }
        }
    }
`;

const AboutUsPage = ({ data }) => {
    const lottieContainer = React.createRef();
    const [activeElement, setActiveElement] = useState(0);
    const [lastElement, setLastElement] = useState(0);
    const [lottieNum, setLottieNum] = useState(0);
    const [visible, setVisible] = useState(0);

    const firstMock = useRef();
    const secondMock = useRef();
    const headerRef = useRef();

    const onArrowClick = (slide, last) => {
        setActiveElement(slide);
        setLottieNum(1);
        setLastElement(last);
    };

    const { width } = useWindowDimensions();

    useEffect(() => {
        const heroAnimation = document.querySelector('#about-hero-anim');
        if (lottieContainer && !heroAnimation) {
            let anim = lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'canvas',
                name: 'about-us',
                loop: false,
                autoplay: true,
                animationData: aboutUsAnimation,
                rendererSettings: {
                    id: 'about-hero-anim',
                },
            });
            anim.addEventListener('enterFrame', () => {
                if (anim.currentFrame >= 355) {
                    anim.removeEventListener('enterFrame');
                    anim.goToAndStop(355, true);
                }
            });
        }
        if (lottieNum === 1) {
            lottie.goToAndStop(355, true);
            lottie.play('about-us');
        }
        if (activeElement === 2 || lastElement === 1) {
            lottieContainer.current.style.opacity = 0;
        }
        if (activeElement < 2 && lastElement > activeElement) {
            firstMock.current.style.opacity = 1;
            secondMock.current.style.opacity = 1;
        }
    }, [lottieContainer, activeElement, lastElement, lottieNum]);

    const handleChangeSlide = (active, last) => {
        setActiveElement(active);
        setLastElement(last);
        if (lastElement === 1 && visible !== 1) {
            setVisible(1);
        }
        if (active === 1) {
            setLottieNum(1)
        }
    };

    const lottieTouch = useCallback(() => {
        handleLottieTouch(lottieContainer);
    }, [lottieContainer]);

    const lottieTransform = useCallback(
        (e) => {
            handleTransformLottie(lottieContainer, e);
        },
        [lottieContainer]
    );

    const lottieStop = useCallback(() => {
        handleLottieStop(lottieContainer);
    }, [lottieContainer]);


    return (
        <Layout>
            <HelmetDatoCms seo={data?.seo?.meta} />
            <PageHeader ref={headerRef} color={activeElement === 1 ? colors.dark : colors.light} />

            <Slider
                autoSlidePercent={50}
                msAnimationTime={0.5}
                swipeDelayPercent={7}
                activeElement={activeElement}
                lastElement={lastElement}
                handleChangeSlide={handleChangeSlide}
                lottieTransform={lottieTransform}
                lottieTouch={lottieTouch}
                lottieStop={lottieStop}
                headerRef={headerRef}
            >
                <PageSection isClipped={true} background={colors.gradient} >
                    <AnimatePresence>
                        {activeElement === 0 && (
                            <>
                                <SlideInElement
                                    top={130}
                                    left={-380}
                                    bg={bgLightElement}
                                    variant={fadeInLeft}
                                    exit="exit"
                                ></SlideInElement>
                                <motion.div
                                    exit="exit"
                                    variants={fadeInUp}
                                    initial="initial"
                                    animate="animate"
                                    style={{ display: 'flex', alignItems: `${width <= 992 ? "flex-end" : "center"}`, flex: 1, paddingBottom: `${width <= 992 ? "10vh" : "none"}` }}
                                >
                                    <Title maxWidth={682} className={'about-us'}>
                                        Driven by a <br />
                                        <Outline color={colors.light}>commitment </Outline>
                                        to our clients
                                    </Title>
                                </motion.div>
                                <Arrow
                                    icon={arrowUp}
                                    style={{ transform: 'rotate(180deg)' }}
                                    onClick={() => onArrowClick(1, 0)}
                                />
                            </>
                        )}
                    </AnimatePresence>
                    <Mock mockImg={heroMock} ref={firstMock} />
                </PageSection>
                <PageSection isClipped={true} background={colors.light} padding={width > 320 ? "8vh 30px" : "10vh 30px"}>
                    <AnimatePresence>
                        {activeElement === 1 && (
                            <>
                                <SlideInElement
                                    top={135}
                                    left={-160}
                                    bg={bgDarkElement}
                                    exit="exit"
                                    variant={fadeInLeft}
                                />
                                {width && width > 320 ? (
                                    <motion.div exit="exit" variants={fadeInUp} initial="initial" animate="animate">
                                        <SubTitle maxWidth={595} color={colors.dark}>
                                            Focusing on relationships and customer service
                                        </SubTitle>
                                        <TagLine color={colors.dark}>{tagLineText}</TagLine>
                                    </motion.div>
                                ) : (
                                    <Scroll>
                                        <motion.div exit="exit" variants={fadeInUp} initial="initial" animate="animate">
                                            <SubTitle maxWidth={595} color={colors.dark}>
                                                Focusing on relationships and customer service
                                            </SubTitle>
                                            <TagLine color={colors.dark}>{tagLineText}</TagLine>
                                        </motion.div>
                                    </Scroll>
                                )}
                                <Arrow
                                    icon={arrowDown}
                                    onClick={() => onArrowClick(2, 0)}
                                />
                            </>
                        )}
                    </AnimatePresence>
                    <Mock mockImg={subHeroMock} ref={secondMock} />
                </PageSection>
                <SlideFooter isClipped={true} isActive={activeElement === 2} handleClick={() => onArrowClick(0, 2)} />
            </Slider>

            <LottieOuter zIndex>
                <LottieInner ref={lottieContainer} />
            </LottieOuter>
        </Layout>
    );
};

export default AboutUsPage;
